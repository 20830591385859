
import { defineComponent, computed, inject, ref } from 'vue'

export default defineComponent({
  setup() {
    const scrollY = inject('scrollY', ref(0))
    const isMobile = inject('isMobile', ref(false))
    //
    const logoStyle = computed(() => {
      if (isMobile.value) {
        return {}
      }
      return {
        transform: `translate3d(0px, ${Math.floor(scrollY.value * 0.4)}px, 0px)`
      }
    })
    //
    return {
      logoStyle
    }
  },
})
