
import { computed, defineComponent, inject, ref } from 'vue'
import { useMicrocmsStore } from '@/store/Microcms'
//
import SectionHead from '@/components/parts/SectionHead.vue'
//
export default defineComponent({
  components: {
    SectionHead,
  },
  //
  setup() {
    // ---
    // Storeから読み込み
    const microcms = useMicrocmsStore()
    //
    const menuPhoto01 = computed(() => {
      return `${microcms.state.menu01.url}?fit=crop&w=800&h=800`
    })
    const menuPhoto02 = computed(() => {
      return `${microcms.state.menu02.url}?fit=crop&w=800&h=800`
    })
    // ---
    // メニューのデータ
    const drinkMenu = computed(() => {
      return microcms.state.drink
    })
    const foodMenu = computed(() => {
      return microcms.state.food
    })
    const desertMenu = computed(() => {
      return microcms.state.desert
    })
    // --- --- ---
    // 写真パララックス用
    //
    const photoElement = ref()
    //
    const scrollY = inject('scrollY', ref(0))
    const isMobile = inject('isMobile', ref(false))
    //
    const photoStyle = computed(() => {
      if (isMobile.value) {
        return {}
      }
      const element = photoElement.value as HTMLDivElement
      if (element) {
        const pt = scrollY.value - element.getBoundingClientRect().y
        return {
          photo01: {
            transform: `translate3d(0px, ${Math.floor(pt * 0.1)}px, 0px)`
          },
          photo02: {
            transform: `translate3d(0px, ${Math.floor(pt * 0.05)}px, 0px)`
          }
        }
      }
      return {}
    })
    //
    return {
      menuPhoto01,
      menuPhoto02,
      //
      drinkMenu,
      foodMenu,
      desertMenu,
      //
      photoElement,
      photoStyle,
      isMobile,
    }
  },
})
