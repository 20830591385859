
import { computed, defineComponent, inject, Ref } from 'vue'
import { useMicrocmsStore } from '@/store/Microcms'
//
import SectionHead from '@/components/parts/SectionHead.vue'
//
export default defineComponent({
  components: {
    SectionHead,
  },

  //
  setup() {
    // ---
    // Storeから読み込み
    // const microcms = useMicrocmsStore()
    const isMobile = inject('isMobile') as Ref<boolean>

    //
    /*
    const storeItems = computed(() => {
      return microcms.state.onlinestore
    })
    */

    //
    const base = computed(() => {
      if (isMobile.value) {
        return {
          width: 160,
          height: 220,
          type: 'small'
        }
      }
      //
      return {
        width: 320,
        height: 480,
        type: 'large'
      }
    })

    //
    return {
      // storeItems,
      base,
    }
  }
})
