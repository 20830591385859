import { inject, InjectionKey, reactive } from "vue"
//
type IMAGE_TYPE = {
  url: string
  width: number
  height: number
}
type MENU_TYPE = {
  fieldId: string
  from: boolean
  name: string
  price: string
}
type STORE_TYPE = {
  fieldId: string
  url: string
}
//
const microcmsStore = () => {
  // ---
  // ステート
  const state = reactive({
    isLoaded: false,
    //
    about: '',
    //
    drink: [] as MENU_TYPE[],
    food: [] as MENU_TYPE[],
    desert: [] as MENU_TYPE[],
    //
    cover: {} as IMAGE_TYPE,
    inside: {} as IMAGE_TYPE,
    menu01: {} as IMAGE_TYPE,
    menu02: {} as IMAGE_TYPE,
    access: {} as IMAGE_TYPE,
    footer: {} as IMAGE_TYPE,
    //
    onlinestore: [] as STORE_TYPE[],
    //
    map: '',
    accessText: '',
  })
  // ---
  // アクション
  //
  const load = async (draft_key: string | undefined = undefined) => {
    //
    let url = process.env.VUE_APP_DATA_URL
    if (draft_key) {
      const query = new URLSearchParams({ draftKey: draft_key})
      url = `${url}?${query}`
    }
    //
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': process.env.VUE_APP_CMS_KEY,
      }
    })
    //
    const data = await res.json()
    // console.log(data)
    //
    state.about = data.about
    //
    state.drink = data.drink
    state.food = data.food
    state.desert = data.desert
    // 画像
    state.cover = data.cover
    state.inside = data.inside
    state.menu01 = data.menu01
    state.menu02 = data.menu02
    state.access = data.access
    state.footer = data.footer
    //
    state.onlinestore = data.onlinestore
    //
    state.map = data.map
    state.accessText = data.accessText
    // --- --- ---
    // ローディングのフラグ
    state.isLoaded = true
  }
  //
  return {
    state,
    load,
  }
}
// ----- ----- -----
// provide / inject用
//
export default microcmsStore
//
export type MicrocmsStore = ReturnType<typeof microcmsStore>
//
export const microcmsStoreKey: InjectionKey<MicrocmsStore> = Symbol('microcmsStore')
//
export const useMicrocmsStore = () => {
  //
  const store = inject(microcmsStoreKey)
  if (!store) {
    throw new Error(`${microcmsStoreKey} is not provided`)
  }
  return store
}
