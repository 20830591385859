
import { defineComponent, provide, ref, onMounted, computed } from 'vue'
//
import { useMicrocmsStore } from '@/store/Microcms'
//
import CoverBackground from '@/components/CoverBackground.vue'
import Cover from '@/components/Cover.vue'
import Navigation from '@/components/Navigation.vue'
import About from '@/components/About.vue'
import Information from '@/components/Information.vue'
import Menu from '@/components/Menu.vue'
import Access from '@/components/Access.vue'
import OnlineStore from '@/components/OnlineStore.vue'
import Social from '@/components/Social.vue'
import Bottom from '@/components/Bottom.vue'
//
export default defineComponent({
  //
  components: {
    CoverBackground,
    Cover,
    Navigation,
    About,
    Information,
    Menu,
    Access,
    OnlineStore,
    Social,
    Bottom,
  },
  //
  setup() {
    // ---
    // urlクエリにドラフトキーがある場合は値が入る
    const draft_key = computed(() => {
      let key = undefined
      if (window) {
        var queryStr = window.location.search.slice(1)
        if (queryStr) {
          // クエリ文字列を & で分割して処理
          queryStr.split('&').forEach(params => {
            // = で分割してkey,valueをオブジェクトに格納
            if (params.split('=')[0] === 'draft_key') {
              key = params.split('=')[1]
            }
          });
        }
      }
      //
      return key
    })
    // --- --- ---
    // MicroCMSから読み込み
    //
    const microcms = useMicrocmsStore()
    microcms.load(draft_key.value)
    const loading = computed(() => microcms.state.isLoaded)
    // --- --- ---
    // プロバイダ
    // ---
    // スクロールを配信
    const scrollY = ref(0)
    provide('scrollY', scrollY)
    //
    const onScrollHandler = () => {
      //
      scrollY.value = window.scrollY
    }
    // ---
    // 画面サイズ
    const isMobile = ref(false)
    provide('isMobile', isMobile)
    //
    const handleMediaQuery = (event: MediaQueryListEvent) => {
      if (event.matches) {
        // PC
        isMobile.value = false
      } else {
        // SP
        isMobile.value = true
      }
    };
    //
    onMounted(() => {
      if (window) {
        // スクロールイベント
        window.addEventListener('scroll', onScrollHandler)
        // 画面サイズ
        const mql = window.matchMedia('(min-width: 577px)')
        mql.addEventListener('change', handleMediaQuery)
        // 初回用イベントの発行
        mql.dispatchEvent(new MediaQueryListEvent('change', mql))
      }
    })
    //
    return {
      loading
    }
  },
})
