
import { computed, defineComponent, inject, ref } from 'vue'
import { useMicrocmsStore } from '@/store/Microcms'
//
export default defineComponent({
  setup() {
    // ---
    // Storeから読み込み
    const microcms = useMicrocmsStore()
    const isMobile = inject('isMobile', ref(true))
    // ---
    const backgroundStyle = computed(() => {
      if (isMobile.value) {
        return {
          backgroundImage: `url(${microcms.state.access.url}?fit=crop&w=750&h=1334)`
        }
      }
      return {
        backgroundImage: `url(${microcms.state.access.url}?fit=crop&w=1800&h=1012)`
      }
    })
    //
    const mapURL = computed(() => {
      return microcms.state.map
    })
    //
    const accessText = computed(() => {
      return microcms.state.accessText
    })
    //
    return {
      backgroundStyle,
      mapURL,
      accessText,
    }
  },
})
