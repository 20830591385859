
import { computed, defineComponent, inject, ref } from 'vue'
import { useMicrocmsStore } from '@/store/Microcms'
//
export default defineComponent({
  setup() {
    // ---
    // Storeから読み込み
    const microcms = useMicrocmsStore()
    const isMobile = inject('isMobile', ref(true))
    // ---
    //
    const backgroundImage = computed(() => {
      if (isMobile.value) {
        return `${microcms.state.cover.url}?fit=crop&w=750&h=1000`
      }
      return `${microcms.state.cover.url}?fit=crop&w=1800&h=1012`
    })
    //
    return {
      backgroundImage
    }
  },
})
