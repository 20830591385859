
import { computed, defineComponent, inject, ref } from 'vue'
import { useMicrocmsStore } from '@/store/Microcms'
//
export default defineComponent({
  setup() {
    // ---
    // Storeから読み込み
    const microcms = useMicrocmsStore()
    const isMobile = inject('isMobile', ref(true))
    //
    const aboutText = computed(() => {
      return microcms.state.about
    })
    // ---
    //
    const backgroundStyle = computed(() => {
      //
      if (isMobile.value) {
        return {
          backgroundImage: `url(${microcms.state.inside.url}?fit=crop&w=750&h=300)`
        }
      }
      return {
        backgroundImage: `url(${microcms.state.inside.url}?fit=crop&w=1800&h=400)`
      }
    })
    //
    return {
      aboutText,
      backgroundStyle,
    }
  },
})
