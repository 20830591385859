import { createApp } from 'vue'
//
import App from './App.vue'
import './registerServiceWorker'
import VueScrollTo from 'vue-scrollto'
//
import microcmsStore, { microcmsStoreKey } from '@/store/Microcms'
// ----- ----- -----
//
const app = createApp(App)
//
// Add Vue directive
app.directive('scroll-to', VueScrollTo)
//
app
  .provide(microcmsStoreKey, microcmsStore())
  .mount('#app')
