
import { defineComponent, ref } from 'vue'
//
import SectionHead from '@/components/parts/SectionHead.vue'
//
type LIST_TYPE = {
  title: string
  description: string
  date: Date
  dateStr: string
  link: string
}
//
export default defineComponent({
  components: {
    SectionHead,
  },
  //
  setup() {
    // --- --- ---
    //
    const list = ref([] as LIST_TYPE[])
    const limit = 5
    // --- --- ---
    //
    fetch(process.env.VUE_APP_RSS_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': process.env.VUE_APP_CMS_KEY,
      }
    }).then((res) => {
      res.json().then((json) => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(json.rss, "text/xml")
        const items = doc.querySelectorAll('item')
        for(let i = 0; i < items.length; i++) {
          if (i < limit) {
            //
            const d = new Date(items[i].querySelector('pubDate')?.innerHTML || Date.now())
            //
            list.value.push({
              title: items[i].querySelector('title')?.innerHTML || '',
              description: items[i].querySelector('description')?.textContent || '',
              date: d,
              dateStr: `${d.getFullYear()}年${d.getMonth()+1}月${d.getDate()}日`,
              link: items[i].querySelector('link')?.innerHTML || '',
            })
          }
        }
      })
    })
    // --- --- ---
    //
    return {
      list
    }
  },
})
